// - Import react components
import React, {Component} from 'react'
import {Typography} from '@material-ui/core'

export default class MasterLoadingComponent extends Component{

    constructor(props) {
        super(props)
    }

    loadProgress() {
        const {error, timedOut, pastDelay} = this.props
        const divStyle = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh'
        }

        if (error) {
            console.log('Root Errors: ' , error)
            window.location.reload()
            // return (
            //     <Typography variant='title' color='primary' style={{marginLeft: '15px'}}>
            //
            //     </Typography>
            // )
        } else if (timedOut) {
            console.log('Timeout Occured: ' , timedOut)
            return (
                <Typography variant='title' color='primary' style={{marginLeft: '15px'}}>
                    It takes long time ...
                </Typography>

            )
        } else if (pastDelay) {
            return (
                <div className='spinner spinner-1 ammartaka-preloader ammartaka-preloader-container' style={divStyle}>
                    <div className='ui-preloader-aamartaka'>
                        <img src={`/images/preloader-aamartaka.png`} alt={'preloader'}/>
                    </div>
                </div>
            )
        } else {
            return (
                <div className='spinner spinner-1 ammartaka-preloader ammartaka-preloader-container' style={divStyle}>
                    <div className='ui-preloader-aamartaka'>
                        <img src={`/images/preloader-aamartaka.png`} alt={'preloader'}/>
                    </div>
                </div>
            )
        }
    }

    render() {
        return (
                this.loadProgress()
        )
    }
}